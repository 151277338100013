import { Copy, FlexBox } from '@cimpress/react-components';
import { FusionCanvas, type OnPaintArgs, type TextOptions } from '@rendering/fusion-react';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { drawBoundingBoxes, drawPreviewBoxes } from '../../../../tools/fusion/boxDrawer';
import classes from '../PreviewCard.module.css';
import type { PreviewRendererProps } from '../types';
import { useLoadCardData } from '../useLoadCardData';
import { ErrorIndicator, LoadingIndicator, PanelSwitcher } from '../components';
import { dimensionsToString } from './helpers';

const CANVAS_DIMENSION = { height: 300 };
const TEXT_OPTIONS: TextOptions = { rtextEnabled: false };

export const DocumentPreviewRenderer = ({ loader }: PreviewRendererProps<'document'>) => {
    const {
        data,
        isLoading,
        cardMetadata: {
            loadingLabel,
            requestTime,
            selectedLogoConfidence,
            documentUrl,
            showBoxesButtons = false,
            showBoundingBoxesAtInit = false,
        },
        hasError,
        errorMessage,
        panels,
        panelIndex,
        onPreviewLoaded,
        onPreviewError,
        onPanelIndexChange,
    } = useLoadCardData(loader);

    // START: Logic from fusion demo to draw bounding and preview boxes
    const [showPreviewBoxes, setShowPreviewBoxes] = useState(false);
    const [showBoundingBoxes, setShowBoundingBoxes] = useState(false);
    const [document, setDocument] = useState(() => data?.document);

    const onPaint = useCallback(
        (results: OnPaintArgs) => {
            const { canvas, layoutResult, paintResult } = results[0];

            const context = canvas.getContext('2d') as CanvasRenderingContext2D;

            if (!context) {
                return;
            }

            if (showPreviewBoxes) {
                const { scalar } = paintResult;
                drawPreviewBoxes({
                    scalar,
                    layoutElements: layoutResult.elements,
                    context,
                    color: '#25C2A0',
                });
            }
            if (showBoundingBoxes) {
                const { scalar } = paintResult;
                drawBoundingBoxes({
                    scalar,
                    layoutElements: layoutResult.elements,
                    context,
                    color: '#1BABEB',
                });
            }

            // If the logic from fusion is not needed, this function should be in
            // the onPaint property of the FusionCanvas component
            onPreviewLoaded();
        },
        [onPreviewLoaded, showPreviewBoxes, showBoundingBoxes],
    );

    useEffect(() => {
        if (showBoundingBoxesAtInit) {
            setShowBoundingBoxes(showBoundingBoxesAtInit);
        }
    }, [showBoundingBoxesAtInit]);

    useEffect(() => {
        if (data?.document) {
            setDocument(data.document);
        }
    }, [data?.document]);
    // END: Logic from fusion demo to draw bounding and preview boxes

    const handleFusionError = (error: unknown) => {
        if (error instanceof TypeError && error.message.includes('getContext')) {
            // Sometimes Fusion tries to render after its canvas is unmounted,
            // we don't want to show an error in that case
            return;
        }

        onPreviewError();
    };

    const panelId = panels[panelIndex]?.id;

    // This is a bug on Fusion side which returns an error in case a document does not contain a font repository url even if a document does not have a text.
    if (document && !document.fontRepositoryUrl) {
        document.fontRepositoryUrl = '';
    }

    return (
        <>
            {isLoading && <LoadingIndicator label={loadingLabel} />}

            {hasError && <ErrorIndicator message={errorMessage} />}

            {!hasError && document && panelId && (
                <div className={classNames(classes.preview, { [classes.hidden]: isLoading })}>
                    <FusionCanvas
                        cimDoc={document}
                        dimension={CANVAS_DIMENSION}
                        textOptions={TEXT_OPTIONS}
                        selector={{ type: 'panel', id: panelId }}
                        onPaint={onPaint}
                        onError={handleFusionError}
                        referrer=""
                    />
                    <span>{dimensionsToString(panels, panelIndex)}</span>
                </div>
            )}

            {/* START: Logic from fusion demo to draw bounding and preview boxes */}

            {showBoxesButtons && (
                <FlexBox flexDirection="column" alignItems="center" justifyContent="center">
                    <button
                        onClick={() => {
                            setShowPreviewBoxes(!showPreviewBoxes);
                            // trigger rerender
                            const newdoc = JSON.parse(JSON.stringify(document));
                            newdoc.ignoreThis = Math.random();
                            setDocument(newdoc);
                        }}
                    >
                        {showPreviewBoxes ? 'Hide ' : 'Show '} preview boxes
                    </button>
                    <button
                        onClick={() => {
                            setShowBoundingBoxes(!showBoundingBoxes);
                            // trigger rerender
                            const newdoc = JSON.parse(JSON.stringify(document));
                            newdoc.ignoreThis = Math.random();
                            setDocument(newdoc);
                        }}
                    >
                        {showBoundingBoxes ? 'Hide ' : 'Show '} bounding boxes
                    </button>
                    {/* END: Logic from fusion demo to draw bounding and preview boxes */}

                    {documentUrl && <Copy value={documentUrl}>documentUrl</Copy>}
                    {requestTime && <div>Request time: {requestTime} ms</div>}
                    {selectedLogoConfidence && <div>Replaced logo confidence: {selectedLogoConfidence}</div>}
                </FlexBox>
            )}
            <PanelSwitcher panelCount={panels.length} panelIndex={panelIndex} onChange={onPanelIndexChange} />
        </>
    );
};
