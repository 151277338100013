import { useEffect, useState, type CSSProperties } from 'react';
import { useCancelableEffect } from '../../tools';
import { getAgsDebugInfoHeader } from '../../api';

export interface AnchorImageProps {
    documentUrl?: string;
    previewUrl: string;
    alt: string;
    useLoadingTransition?: boolean;
}
const ANCHOR_IMAGE_STYLE: CSSProperties = {
    maxWidth: '100%',
    minWidth: '10rem',
    transition: 'opacity 0.5s ease-in-out',
};
const LOADING_IMAGE_STYLE: CSSProperties = { ...ANCHOR_IMAGE_STYLE, opacity: 0.2 };

export const AnchorImage = ({ documentUrl = '', previewUrl, alt, useLoadingTransition = false }: AnchorImageProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const [imageFooter, setImageFooter] = useState<string | undefined>(undefined);

    useCancelableEffect(
        (signal) => {
            (async () => {
                if (documentUrl.includes('v0/composition:compose')) {
                    const agsDebugInfo = await getAgsDebugInfoHeader(documentUrl, signal);
                    const confidenceOfSelectedLogo = agsDebugInfo?.compose?.confidenceOfLogoUsedForReplacement;
                    if (confidenceOfSelectedLogo) {
                        setImageFooter(`Replaced logo confidence: ${confidenceOfSelectedLogo}`);
                    }
                }
            })();
        },
        [documentUrl],
    );

    useEffect(() => {
        setIsLoading(true);
    }, [previewUrl]);

    const handleLoad = () => setIsLoading(false);

    return (
        <>
            <a href={documentUrl} target="blank">
                <img
                    src={previewUrl}
                    alt={alt}
                    style={isLoading && useLoadingTransition ? LOADING_IMAGE_STYLE : ANCHOR_IMAGE_STYLE}
                    onLoad={handleLoad}
                />
            </a>
            {imageFooter ? <div style={{ display: 'grid', justifyContent: 'center' }}>{imageFooter}</div> : <></>}
        </>
    );
};
