import { useCallback, useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import type { CimpressDocument } from '@mcp-artwork/cimdoc-types-v2';
import { useGetLayoutDataAndContent, type LayoutContext } from '../../api/camAndDiscoveryApi';
import {
    type Card,
    CardBuilder,
    Control,
    CustomSelector,
    Page,
    PreviewSection,
    type SelectorOption,
} from '../../Components';
import { isUrl } from '../../tools';
import { getCompositionGenerateUrl, getPhotoSceneUrl, getTransientDocumentUrl } from '../../api';
import { AGS_API_KEY } from '../../constants';
import type { DocumentUrlPreviewData } from '../../Components/common/previewCard/types';
import { AUTHORIZATION_HEADERS } from '../../api/constants';
import { getSurfaceUrlForProduct } from './constants';

const fullbleedSourceDocuments: SelectorOption[] = [
    {
        label: 'Sample document 1',
        value: 'https://storage.documents.cimpress.io/v3/documents/6c461b13-69e8-462a-98e8-24ccbd529cf0/revisions/72415e3a-c6f2-4813-ace0-29b488d6d43e',
    },
    {
        label: 'Sample document 2',
        value: 'https://storage.documents.cimpress.io/v3/documents/56b54064-02f1-41d4-9f27-e112b70d2492/revisions/38f68e15-c2f2-4ee0-ad00-54b84bf4ce23',
    },
];

function getLayoutProducts(layout: any) {
    if (!layout || !layout.layoutMetadata || !layout.layoutMetadata.targetProductCategories) return [];
    return layout.layoutMetadata.targetProductCategories as string[];
}

const SupportedProductsPage = () => {
    const [docUrl, setDocUrl] = useState(fullbleedSourceDocuments[0].value);
    const [layoutContexts, setLayoutContexts] = useState<LayoutContext[]>([]);
    const [cards, setCards] = useState<Card[]>([]);

    const { fetchLayoutDataAndContent } = useGetLayoutDataAndContent();

    const fetchLayoutsFromCam = useCallback(() => {
        fetchLayoutDataAndContent().then((contexts) => {
            const filteredContexts = contexts.filter(
                (x) => !!x.camData.collections.find((x) => x.toLowerCase() === 'dynamic layout'),
            );
            setLayoutContexts(filteredContexts);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const products = useMemo(() => {
        const uniqueProducts = Array.from(
            new Set(
                layoutContexts.flatMap((x) => {
                    return [...getLayoutProducts(x.layout)];
                }),
            ).values(),
        );
        return uniqueProducts;
    }, [layoutContexts]);

    useEffect(() => {
        fetchLayoutsFromCam();
    }, [fetchLayoutsFromCam]);

    useEffect(() => {
        // Generate cards
        const newCards = products.map((productName) => {
            return CardBuilder.ofType('documentUrl')
                .withTitle(productName)
                .withLoader(async () => {
                    const surfaceUrl = getSurfaceUrlForProduct(productName);
                    const photoSceneUrl = (await getPhotoSceneUrl(surfaceUrl)) || '';
                    const generateUrl = getCompositionGenerateUrl({
                        apiKey: AGS_API_KEY,
                        apiVersion: 'v3',
                        productName,
                        sourceDocumentUrl: docUrl,
                        surfaceSpecificationUrl: getSurfaceUrlForProduct(productName),
                    });
                    const response = await axios.get<CimpressDocument>(generateUrl, { headers: AUTHORIZATION_HEADERS });
                    const generatedCimDoc = response.data;
                    const result: DocumentUrlPreviewData = {
                        documentUrl: getTransientDocumentUrl(generatedCimDoc),
                        photoSceneUrl,
                    };
                    return result;
                })
                .build();
        });
        setCards(newCards);
    }, [docUrl, products]);

    return (
        <Page>
            <Page.Title>Supported products</Page.Title>
            <Page.Controls fullWidth>
                <Control>
                    <Control.Title>Source document</Control.Title>
                    <CustomSelector
                        setValue={setDocUrl}
                        data={fullbleedSourceDocuments}
                        selectorLabel="Document"
                        textfieldLabel="Document URL"
                        value={docUrl}
                        validator={isUrl}
                    ></CustomSelector>
                </Control>
            </Page.Controls>
            <Page.Content fullWidth>
                <PreviewSection showIntro={false} rows={[cards]} />
            </Page.Content>
        </Page>
    );
};

export default SupportedProductsPage;
