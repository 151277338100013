import axios from 'axios';

export const uploadJsonDocumentAndGetDocumentUrl = async (document: any, signal?: AbortSignal): Promise<string> => {
    try {
        const response = await axios.post('https://storage.documents.cimpress.io/v3/documents', document, {
            signal,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (response.status !== 200) {
            throw new Error('Failed to upload document');
        }

        return response.data.documentUrl;
    } catch (error) {
        console.error('Error uploading document:', error);
        throw error;
    }
};
