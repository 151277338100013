import { Card } from '@cimpress/react-components';
import {
    DocumentPreviewRenderer,
    DocumentUrlPreviewRenderer,
    ImageUrlPreviewRenderer,
    ChildComponentRenderer,
} from './renderers';
import type { PreviewCardProps, PreviewType } from './types';

import classes from './PreviewCard.module.css';

const RENDERERS: Record<PreviewType, any> = {
    document: DocumentPreviewRenderer,
    documentUrl: DocumentUrlPreviewRenderer,
    imageUrl: ImageUrlPreviewRenderer,
    childComponent: ChildComponentRenderer,
};

export const PreviewCard = <T extends PreviewType>({
    title,
    type,
    loader,
    size = 'medium',
    footer,
}: PreviewCardProps<T>) => {
    const PreviewRenderer = RENDERERS[type];

    if (!PreviewRenderer) {
        return <div>Unsupported card type</div>;
    }

    return (
        <Card className={size === 'small' ? classes.card : classes[`card-${size}`]} isMinimal header={title}>
            <div className={classes.previewCard}>
                <PreviewRenderer loader={loader} />
                {footer && (
                    <div className={classes.previewCardFooter}>
                        {footer.status && (
                            <div
                                className={
                                    footer.status.type === 'success' ? classes.statusSuccess : classes.statusError
                                }
                            >
                                Status: {footer.status.code}
                            </div>
                        )}
                        {footer.responseTime && (
                            <div className={classes.responseTime}>Response time: {footer.responseTime}ms</div>
                        )}
                    </div>
                )}
            </div>
        </Card>
    );
};
