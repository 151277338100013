import { useState } from 'react';
import axios from 'axios';
import { AUTHORIZATION_HEADERS } from './constants';

const CAMS_BASE_URL = 'https://prod.cams.contentauthoring.cimpress.io/api/v4';
const CAMS_ARTWORK_GENERATION_CONTENT_AREA_ID = 'GcIU6QLfU0WKH8N8FDhG8A';
const CIMPRESS_ESSENTIAL_ACCOUNT_ID = 'g2Ez5VaoZWoqU22XqPjTLU';

type CamAssetsResponse = {
    data: CamsAssetItem[];
};

type CamVersionItem = {
    id: string;
    assetId: string;
    managementAssetId: string;
    isDiscoverable: boolean;
    contentAsset: {
        uploadInfo: {
            id: string;
            metadata: string;
            tags: string[];
            fileName: string;
            fileUrl: string;
            versionNumber: number;
        };
    };
    versionNumber: number;
};
export type CamsAssetItem = {
    id: string;
    assetName: string;
    latestVersion: CamVersionItem;
    publishedVersion: CamVersionItem;
    collections: string[];
    tags: string[];
};

type CreateAssetResponse = {
    id: string;
};

export async function getLayoutAssetsDataForTenant(signal?: AbortSignal) {
    const params = new URLSearchParams([
        ['assetKind', 'universal'],
        ['assetType', 'management'],
        ['limit', '100'],
        ['offset', '0'],
        ['requestor', 'ags'],
        ['published', 'true'],
    ]);
    const camLayoutsUrl = `${CAMS_BASE_URL}/${CIMPRESS_ESSENTIAL_ACCOUNT_ID}/${CAMS_ARTWORK_GENERATION_CONTENT_AREA_ID}/assets?${params.toString()}`;
    const response = await axios.get<CamAssetsResponse>(camLayoutsUrl, {
        headers: AUTHORIZATION_HEADERS,
        signal,
    });
    return response.data.data;
}

export async function getLayoutAssetData(id: string) {
    const url = `${CAMS_BASE_URL}/${CIMPRESS_ESSENTIAL_ACCOUNT_ID}/${CAMS_ARTWORK_GENERATION_CONTENT_AREA_ID}/assets/${id}`;
    const response = await axios.get<CamsAssetItem>(url, { headers: AUTHORIZATION_HEADERS });
    return response.data;
}

export async function getLayoutContent(url: string) {
    const response = await axios.get<any>(url);
    return response.data;
}

async function createCreativeAsset(
    payload: any,
    assetName: string,
    collections: string[],
    tags: string[],
): Promise<string> {
    const url = `${CAMS_BASE_URL}/${CIMPRESS_ESSENTIAL_ACCOUNT_ID}/${CAMS_ARTWORK_GENERATION_CONTENT_AREA_ID}/assets/creative`;
    const data = new FormData();
    data.append('asset', new Blob([JSON.stringify(payload)], { type: 'application/json' }), `${assetName}.json`);
    data.append(
        'requestBody',
        JSON.stringify({
            assetName,
            tags,
            collections,
        }),
    );
    const response = await axios.post<CreateAssetResponse>(url, data, {
        headers: { ...AUTHORIZATION_HEADERS, 'Content-Type': 'multipart/form-data' },
    });
    return response.data.id;
}

async function createNewManagementAsset(
    creativeAssetId: string,
    assetName: string,
    collections: string[],
    tags: string[],
): Promise<string> {
    const url = `${CAMS_BASE_URL}/${CIMPRESS_ESSENTIAL_ACCOUNT_ID}/${CAMS_ARTWORK_GENERATION_CONTENT_AREA_ID}/assets/management/universal`;
    const result = await axios.post<CreateAssetResponse>(
        url,
        JSON.stringify({
            masterFile: {
                assetId: creativeAssetId,
                versionNumber: 1,
            },
            contentTags: tags,
            contentCollections: collections,
            isDiscoverable: true,
            tags,
            collections,
            assetName,
        }),
        {
            headers: { ...AUTHORIZATION_HEADERS, 'Content-Type': 'application/json', requestor: 'ags' },
        },
    );
    return result.data.id;
}

async function updateManagementAssetPublishState(managementAssetId: string, state: boolean) {
    const data = JSON.stringify({ isDiscoverable: state });
    const url = `${CAMS_BASE_URL}/${CIMPRESS_ESSENTIAL_ACCOUNT_ID}/${CAMS_ARTWORK_GENERATION_CONTENT_AREA_ID}/assets/management/universal/${managementAssetId}/versions/1:publish`;
    await axios.patch(url, data, {
        headers: { ...AUTHORIZATION_HEADERS, 'Content-Type': 'application/json-patch+json', requestor: 'ags' },
    });
}

async function updateManagementAssetCollectionsAndTags(
    managementAssetId: string,
    collections: string[],
    tags: string[],
) {
    const data = JSON.stringify({ contentTags: tags, tags, contentCollections: collections, collections });
    const url = `${CAMS_BASE_URL}/${CIMPRESS_ESSENTIAL_ACCOUNT_ID}/${CAMS_ARTWORK_GENERATION_CONTENT_AREA_ID}/assets/management/universal/${managementAssetId}`;
    await axios.patch(url, data, {
        headers: { ...AUTHORIZATION_HEADERS, 'Content-Type': 'application/json-patch+json', requestor: 'ags' },
    });
}

async function deleteManagementAsset(managementAssetId: string) {
    const url = `${CAMS_BASE_URL}/${CIMPRESS_ESSENTIAL_ACCOUNT_ID}/${CAMS_ARTWORK_GENERATION_CONTENT_AREA_ID}/assets/${managementAssetId}`;
    await axios.delete(url, {
        headers: { ...AUTHORIZATION_HEADERS, requestor: 'ags' },
    });
}

export async function updateLayoutTagsAndCollections(
    assetId: string,
    collections: string[],
    tags: string[],
    updateProgressCallback: (status: string) => void,
    onSuccess: () => void,
    onFailure: (error?: string) => void,
) {
    updateProgressCallback('Updating management asset collections and tags...');
    try {
        await updateManagementAssetCollectionsAndTags(assetId, collections, tags);
    } catch (error: any) {
        updateProgressCallback('Error during asset patch:' + error);
        onFailure(error.toString());
        return;
    }
    onSuccess();
}

export async function removeManagementAsset(
    assetId: string,
    updateProgressCallback: (status: string) => void,
    onSuccess: () => void,
    onFailure: (error?: string) => void,
) {
    updateProgressCallback('Unpublishing old management asset...');
    try {
        await updateManagementAssetPublishState(assetId, false);
        updateProgressCallback('Deleting old management asset...');
        await deleteManagementAsset(assetId);
    } catch (error: any) {
        updateProgressCallback('Error during old asset takedown:' + error);
        onFailure(error.toString());
        return;
    }
    onSuccess();
}

export async function updateLayoutAsset(
    assetName: string,
    oldAssetId: string | undefined,
    newAssetPayload: any,
    collections: string[],
    tags: string[],
    updateProgressCallback: (status: string) => void,
    onSuccess: () => void,
    onFailure: (error?: string) => void,
) {
    updateProgressCallback('Uploading new creative asset...');
    try {
        const newAssetId = await createCreativeAsset(newAssetPayload, assetName, collections, tags);
        updateProgressCallback('Creating management asset...');
        await createNewManagementAsset(newAssetId, assetName, collections, tags);
    } catch (error: any) {
        updateProgressCallback('Error during new asset upload:' + error);
        onFailure(error.toString());
        return;
    }
    if (oldAssetId) {
        await removeManagementAsset(oldAssetId, updateProgressCallback, onSuccess, onFailure);
    }
}

export type LayoutContext = {
    camData: CamsAssetItem;
    layout: any;
};

export function useGetLayoutDataAndContent() {
    const [isLoading, setIsLoading] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState('Loading assets list...');

    async function fetchLayoutDataAndContent() {
        setIsLoading(true);
        const layoutItems = await getLayoutAssetsDataForTenant();
        const camDataResults = await Promise.allSettled(layoutItems);
        setLoadingStatus('Loading layout contents...');
        const validResults = camDataResults.filter((result) => result.status === 'fulfilled');
        const validLayoutItems = layoutItems.filter((x) => !!validResults.find((a) => a.value.id === x.id));
        const contentPromises = validLayoutItems.map((item) =>
            getLayoutContent(item.publishedVersion.contentAsset.uploadInfo.fileUrl),
        );
        const resolvedContentPromises = await Promise.allSettled(contentPromises);
        const contexts = resolvedContentPromises
            .map((content, index) => {
                if (content.status === 'rejected') return null;
                return {
                    camData: validResults.find((a) => a.value.id === validLayoutItems[index].id)!.value,
                    layout: content.value,
                    changesMade: false,
                } as LayoutContext;
            })
            .filter((a) => !!a) as LayoutContext[];
        setIsLoading(false);
        return contexts;
    }

    return { isLoading, loadingStatus, fetchLayoutDataAndContent };
}
