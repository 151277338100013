import type { FC } from 'react';
import type {
    Card,
    PreviewCardProps,
    PreviewData,
    PreviewDataLoader,
    PreviewType,
    PreviewDataFooter,
    CompletedCardBuilder,
} from './types';

interface TypedCardBuilder<T extends PreviewType> {
    withTitle: (title: string) => TitledCardBuilder<T>;
}

interface TitledCardBuilder<T extends PreviewType> {
    withData: (data: PreviewData<T>) => CompletedCardBuilder;
    withLoader: (loader: PreviewDataLoader<T>) => CompletedCardBuilder;
    withChildComponent: (child: FC) => CompletedCardBuilder;
    withFooter: (footer: PreviewDataFooter) => CompletedCardBuilder;
}

export class CardBuilder<T extends PreviewType>
    implements TypedCardBuilder<T>, TitledCardBuilder<T>, CompletedCardBuilder
{
    static ofType<T extends PreviewType>(type: T): TypedCardBuilder<T> {
        return new CardBuilder(type);
    }

    private title = '';
    private type: T;

    private data?: PreviewData<T>;
    private loader?: PreviewDataLoader<T>;
    private child?: FC;
    private footer?: PreviewDataFooter;

    private constructor(type: T) {
        this.type = type;
    }

    withTitle(title: string): TitledCardBuilder<T> {
        this.title = title;

        return this;
    }

    withData(data: PreviewData<T>): CompletedCardBuilder {
        this.data = data;

        return this;
    }

    withLoader(loader: PreviewDataLoader<T>): CompletedCardBuilder {
        this.loader = loader;

        return this;
    }

    withChildComponent(child: FC): CompletedCardBuilder {
        this.child = child;

        return this;
    }

    withFooter(footer: PreviewDataFooter): CompletedCardBuilder {
        this.footer = footer;
        return this;
    }

    build(): Card {
        const { title, type, data, loader, child, footer } = this;

        let card: PreviewCardProps<T> | undefined;

        if (data) {
            card = {
                title,
                type,
                loader: async () => data,
                footer,
            };
        } else if (loader) {
            card = {
                title,
                type,
                loader,
                footer,
            };
        } else if (child) {
            card = {
                title,
                type,
                loader: async () => ({ childComponent: child }) as PreviewData<T>,
                footer,
            };
        }

        if (!card) {
            throw new Error('Card build error: no data/loader/child provided');
        }

        return card as Card;
    }
}
