import { FourOhFour } from '@cimpress/react-components';
import { type JSX, Suspense } from 'react';
import { ResizePage as ResizeV0Page } from '../Pages/TestingPages/Resize';
import SupportedProductsPage from '../Pages/ManagementPages/SupportedProductsPage';
import {
    BatchGenerateFromFullBleed,
    BatchGenerateReportNoRequiredAssets,
    BatchResizeByDocument,
    BatchResizeByProduct,
    BatchSherbertVsBrandExtraction,
    BatchTransferCustomization,
    BatchTransferStyle,
    BoundingBoxQa,
    ComposeQa,
    RegressionTest,
    ComposeExperimentalQa,
    DepseudonymizePiiData,
    EnrichmentPage,
    GenerateV3WithSherbertResultsPage,
    GenerateWithDynamicLayoutPage,
    KrReportAdjustForSubstratePage,
    KrReportAdjustForSurfacePage,
    KrReportImproveContrastPage,
    KrReportResizePage,
    KrReportTransferCustomizationPage,
    KrReportTransferStylePage,
    LayoutComposerVsGenDesignResults,
    PersonalizedGallery,
    PreviewCardVisualizationsPage,
    PurposelessTransferCustomizations,
    QaPage,
    ImageToCimdoc,
    ResizeQaPage,
    LayoutAssetManagerPage,
    InspirationGenerateImageFromTextPage,
    InspirationGenerateLogoFromTextPage,
    InspirationGenerateFromImagePage,
    LocalQa,
    BatchBrandExtractionVsImageMind,
} from './lazyLoaders';
import { PageLoadFallback } from './PageLoadFallback';

export interface Route {
    href: string;
    title: string;
    element: JSX.Element;
}

export interface RouteWithCategory extends Route {
    category: string;
}

export interface RouteCategories {
    categoryName: string;
    routes: Route[];
}

export const krReportRoutes: Route[] = [
    {
        href: 'krreport/resize',
        title: 'Resize',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <KrReportResizePage />
            </Suspense>
        ),
    },
    {
        href: 'krreport/adjustforsubstrate',
        title: 'Adapt to Substrate Color',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <KrReportAdjustForSubstratePage />
            </Suspense>
        ),
    },
    {
        href: 'krreport/adjustforsurface',
        title: 'Adapt to Product Surface',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <KrReportAdjustForSurfacePage />
            </Suspense>
        ),
    },
    {
        href: 'krreport/transferstyle',
        title: 'Transfer Style',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <KrReportTransferStylePage />
            </Suspense>
        ),
    },
    {
        href: 'krreport/transfercustomizations',
        title: 'Transfer Customizations',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <KrReportTransferCustomizationPage />
            </Suspense>
        ),
    },
    {
        href: 'krreport/improveartworkcontrast',
        title: 'Improve Artwork Contrast',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <KrReportImproveContrastPage />
            </Suspense>
        ),
    },
];

export const batchRoutes: Route[] = [
    {
        href: 'batch-transfer',
        title: 'Batch Transfer Customizations',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <BatchTransferCustomization />
            </Suspense>
        ),
    },
    {
        href: 'batch-transfer-style',
        title: 'Batch Transfer Style',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <BatchTransferStyle />
            </Suspense>
        ),
    },
    {
        href: 'by-product',
        title: 'Batch Resize by Product',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <BatchResizeByProduct />
            </Suspense>
        ),
    },
    {
        href: 'by-document',
        title: 'Batch Resize by Document',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <BatchResizeByDocument />
            </Suspense>
        ),
    },
    {
        href: 'batch-fullbleed-generate',
        title: 'Batch Fullbleed Generate',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <BatchGenerateFromFullBleed />
            </Suspense>
        ),
    },
    {
        href: 'batch-generate-report',
        title: 'Batch Generate Report NoRequiredAssets',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <BatchGenerateReportNoRequiredAssets />
            </Suspense>
        ),
    },
    {
        href: 'batch-sherbert-vs-brand-extraction',
        title: 'Batch SherbertExtraction vs BrandExtraction',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <BatchSherbertVsBrandExtraction />
            </Suspense>
        ),
    },
    {
        href: 'batch-brandExtaction-vs-imageMind-extraction',
        title: 'Batch BrandExtraction vs ImageMind',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <BatchBrandExtractionVsImageMind />
            </Suspense>
        ),
    },
];

export const testingRoutes: Route[] = [
    {
        href: 'generate-v3-with-sherbert',
        title: 'Generate V3 with Sherbert Result',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <GenerateV3WithSherbertResultsPage />
            </Suspense>
        ),
    },
    {
        href: 'depseudonymize-pii-data',
        title: 'Depseudonymize PII Data',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <DepseudonymizePiiData />
            </Suspense>
        ),
    },
    {
        href: 'personalized-gallery',
        title: 'Personalized Gallery',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <PersonalizedGallery />
            </Suspense>
        ),
    },
    {
        href: 'purposeless-transfer-customizations',
        title: 'Purposeless Transfer Customizations',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <PurposelessTransferCustomizations />
            </Suspense>
        ),
    },
    {
        href: 'preview-cards',
        title: 'PreviewCard Visualizations',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <PreviewCardVisualizationsPage />
            </Suspense>
        ),
    },
    {
        href: 'layout-composer-results',
        title: 'Layout Composer Vs Gendesign Results.',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <LayoutComposerVsGenDesignResults />
            </Suspense>
        ),
    },
    {
        href: 'resize-v0',
        title: 'Experimental Resize',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <ResizeV0Page />
            </Suspense>
        ),
    },
    {
        href: 'cimdoc-enrichment',
        title: 'CimDoc Enrichment',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <EnrichmentPage />
            </Suspense>
        ),
    },
    {
        href: 'generate-v0-dynamic-layout',
        title: 'Generate with Dynamic Layout V0',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <GenerateWithDynamicLayoutPage />
            </Suspense>
        ),
    },
    {
        href: 'inspiration-generate-image-from-text',
        title: 'Inspiration Generate Image From Text',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <InspirationGenerateImageFromTextPage />
            </Suspense>
        ),
    },
    {
        href: 'inspiration-generate-from-image',
        title: 'Inspiration Generate From Image',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <InspirationGenerateFromImagePage />
            </Suspense>
        ),
    },
    {
        href: 'image-to-cimdoc',
        title: 'Image To Cimdoc',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <ImageToCimdoc />
            </Suspense>
        ),
    },
    {
        href: 'inspiration-generate-logo-from-text',
        title: 'Inspiration Generate Logo From Text',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <InspirationGenerateLogoFromTextPage />
            </Suspense>
        ),
    },
];

export const qaRoutes: Route[] = [
    {
        href: 'qa',
        title: 'Migration',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <QaPage />
            </Suspense>
        ),
    },
    {
        href: 'resize-qa',
        title: 'Resize',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <ResizeQaPage />
            </Suspense>
        ),
    },
    {
        href: 'compose-qa',
        title: 'Compose',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <ComposeQa />
            </Suspense>
        ),
    },
    {
        href: 'bounding-box-qa',
        title: 'Bounding Boxes',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <BoundingBoxQa />
            </Suspense>
        ),
    },
    {
        href: 'regression-test',
        title: 'Regression Test',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <RegressionTest />
            </Suspense>
        ),
    },
    {
        href: 'compose-experimental-qa',
        title: 'Compose Experimental',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <ComposeExperimentalQa />
            </Suspense>
        ),
    },
    {
        href: 'local-qa',
        title: 'Local QA',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <LocalQa />
            </Suspense>
        ),
    },
];

export const managementRoutes: Route[] = [
    {
        href: 'layoutassetsmanager',
        title: 'Layout Assets Management (CAM)',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <LayoutAssetManagerPage />
            </Suspense>
        ),
    },
    {
        href: 'supportedproducts',
        title: 'Supported Products',
        element: (
            <Suspense fallback={<PageLoadFallback />}>
                <SupportedProductsPage />
            </Suspense>
        ),
    },
];

const fourOhFour: Route = {
    href: '*',
    title: '404 - Not Found',
    element: (
        <FourOhFour message="Oops! The page you are looking for doesn't exist. Please check the URL or go back to the homepage." />
    ),
};

export const routesByCategory: RouteCategories[] = [
    {
        categoryName: 'KR Reports',
        routes: krReportRoutes,
    },
    {
        categoryName: 'Batch Pages',
        routes: batchRoutes,
    },
    {
        categoryName: 'Testing Pages',
        routes: testingRoutes,
    },
    {
        categoryName: 'QA Pages',
        routes: qaRoutes,
    },
    {
        categoryName: 'Management Pages',
        routes: managementRoutes,
    },
    {
        categoryName: 'Error',
        routes: [fourOhFour],
    },
];

export const routes: RouteWithCategory[] = routesByCategory.flatMap(({ categoryName: name, routes }) =>
    routes.map((route) => ({ ...route, category: name })),
);
